<template>
    <div></div>
</template>

<script>
export default {
    beforeCreate(){
        parent?.window?.authComplete?.(this.$route.query);
        opener?.window?.authComplete?.(this.$route.query);
        window.close();
    }
}
</script>